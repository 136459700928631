import { disableCache } from '@iconify/react'
import ReactDOM from 'react-dom/client'
import AppFactory from './factory/AppFactory'
import reportWebVitals from './reportWebVitals'
/**
 * This file is the entry point for the client-side of the application.
 */

reportWebVitals()
// Disable iconify caching in LocalStorage
disableCache('local')

/**
 * This variable is used to store the EnvConfig encrypted token sended by the server to the client.
 */
let envConfigToken: string | undefined = undefined

if (typeof window !== 'undefined') {
  envConfigToken = (window as any).__data__
}

const appElement = document.getElementById('root')
if (appElement) {
  ReactDOM.hydrateRoot(appElement, <AppFactory data={envConfigToken} />)
}
