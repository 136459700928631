import { setConfigEnvs } from '@/config/envs'
import { Center, Flex, Text } from '@chakra-ui/react'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { initializeApp } from 'firebase/app'
import { lazy, Suspense, useEffect, useState } from 'react'

const TemplateProvider = lazy(
  () =>
    import(
      /* webpackChunkName: "@fh-provider" */ '@/components/templates/templateProvider/index'
    ),
)

const AppFactory = (envData: any) => {
  const [error, setError] = useState(false)
  const [envs, setEnvs] = useState(false)

  const {
    VITE_ORGANIZATION_URL,
    VITE_REMOTE_ENV_SECRET,
    VITE_REST_API_ENDPOINT,
  } = import.meta.env

  useEffect(() => {
    getEnvs()
    // eslint-disable-next-line
  }, [])

  const decryptEnv = async (encryptedEnv: string) => {
    try {
      const decryptedEnv = AES.decrypt(encryptedEnv, VITE_REMOTE_ENV_SECRET)
      const data = JSON.parse(decryptedEnv.toString(Utf8))
      setConfigEnvs({
        adsEnabled: data.adsEnabled,
        muxKey: data.muxKey,
        organization: data.organization,
        remoteConfigSecret: data.remoteConfigSecret,
        googleTag: data.googleTag,
        facebookTag: data.facebookTag,
        playerLogo: data.playerLogo,
        footer: data.footer,
        termsUrl: data.termsUrl,
        policyUrl: data.policyUrl,
        paymentMethods: data.paymentMethods,
        seasonEnabled: data.seasonEnabled,
        inspirePaymentUrl: data.inspirePaymentUrl,
        inspireTenantId: data.inspireTenantId,
        isCommunity: data.isCommunity,
        onesignalAppId: data.onesignalAppId,
        onesignalSafariWebId: data.onesignalSafariWebId,
      })
      setEnvs(true)
      // Initialize firebase app
      initializeApp({
        apiKey: data?.firebaseApiKey,
        authDomain: data?.firebaseDomain,
        databaseURL: data?.firebaseDatabaseUrl,
        projectId: data?.firebaseProject,
        storageBucket: data?.firebaseBucket,
        messagingSenderId: data?.firebaseSender,
        appId: data?.firebaseAppId,
        measurementId: data?.firebaseMeasurementId,
      })
    } catch (err: any) {
      setError(err.message)
    }
  }

  const getEnvs = async () => {
    if (envData.data !== undefined) return decryptEnv(envData.data)
    const env = window.TENANT_CONFIG
    const origin = VITE_ORGANIZATION_URL ?? `https://${window.location.host}`
    if (env && env !== 'TENANT_DATA') return decryptEnv(env)

    await fetch(`https://${VITE_REST_API_ENDPOINT}/env-config`, {
      headers: {
        organization: origin ?? '',
      },
    })
      .then((res) => res.json())
      .then((data) => decryptEnv(data.result))
      .catch((error) => {
        setError(error.message)
      })
  }

  if (error)
    return (
      <Center w={'98vw'} h={'98vh'}>
        <Flex
          borderRadius="8px"
          w="100%"
          maxW="500px"
          h="360px"
          bg="#F6F6F6"
          flexDirection="column"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          <Flex
            bg="red"
            w="100%"
            h="60%"
            borderRadius="8px 8px 0px 0px"
            justifyContent="center"
            alignItems="center"
            color="white"
            fontWeight="600"
            flexDir="column"
            gridGap="1em"
          >
            <Text my={1} fontSize={'1.6rem'}>
              Platform Not Found!
            </Text>
          </Flex>
          <Flex
            w="100%"
            h="40%"
            justifyContent="center"
            alignItems="center"
            fontWeight="500"
          >
            <Text fontSize={'1.3rem'} color="#666666">
              Please check your url and try again!
            </Text>
          </Flex>
        </Flex>
      </Center>
    )

  return (
    <>
      {envs ? (
        <Suspense fallback={null}>
          <TemplateProvider />
        </Suspense>
      ) : null}
    </>
  )
}

export default AppFactory
